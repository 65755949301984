<template>
<!-- 网上发票明细 -->
    <div>
        <div class="content bascform">
            <div class="tableList" >
                <div class="table">
                  <el-table :data="invioceDetail" ref="tabledeatailData" max-height="350"  @selection-change="selectDataAction" @select="onSelect" @select-all="onSelect" style="width: 100%">
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column prop="onlineInvoiceNumber" label="网上发票号" width="200"></el-table-column>
                      <el-table-column prop="onlineInvoiceItemNumber" label="发票行号" width="120"> </el-table-column>
                      <el-table-column prop="prepayIdentify" label="预付(Y/N)" width="150"></el-table-column>
                      <el-table-column prop="invoiceAmount" label="开票金额" > </el-table-column>
                      <el-table-column prop="applicationAmount" label="本次申请金额" width="120" :show-overflow-tooltip="true">
                      </el-table-column>
                      <el-table-column prop="appliedAmount" label="已申请金额" width="120"> </el-table-column>
                      <el-table-column prop="prepaidSterilisation" label="预付冲销"  width="100">
                        <template #default="scope">
                          <el-button size="mini" type="text" @click="handEdit(scope.$index, scope.row)">预付冲销</el-button>
                        </template>
                      </el-table-column>
                      <el-table-column prop="prepayTotal" label="预付合计" > </el-table-column>
                      <el-table-column prop="adjustmentAmount" label="调整金额" >
                        <template #default="scope">
                          <el-input class="inputchange" v-model="scope.row.adjustmentAmount" @input="changeAdjustmentAmount(scope.row)" placeholder=""></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remainingApplicableAmount" label="剩余可申请金额" width="120"> </el-table-column>
                      <el-table-column prop="purchaseOrderCode" label="采购单编号" width="120"> </el-table-column>
                      <el-table-column prop="purchaseOrderItemCode" label="采购单行号" width="120"> </el-table-column>
                      <el-table-column prop="materielCode" label="物料编号" width="150"> </el-table-column>
                      <el-table-column prop="materielDesc" label="物料描述" width="195" :show-overflow-tooltip="true"> </el-table-column>
                      <el-table-column prop="invoiceUnitPrice" label="单价" > </el-table-column>
                      <el-table-column prop="invoiceNumber" label="开票数量" > </el-table-column>
                      <el-table-column prop="taxRate" label="税率" > </el-table-column>
                  </el-table>
                </div>
            </div>
        </div>
      <dialog-box  ref="dialogbox" dialogWidth="580px" :dialogdata="dialogdata"  :dialogShow="dialogShow" @handleClose='handleClose' title="预付冲销" componentName="PrepaidSterilisationdialog"
              :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
      </dialog-box>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'OnlineinvoiceDetailstable',
  props: ['invioceDetail'],
  data () {
    // var data = this.invioceDetail
    return {
      dialogShow: false,
      orderCode: '',
      rowNum: '',
      selectData: [],
      dialogdata: []
    }
  },
  methods: {
    changeAdjustmentAmount (row) {
      row.applicationAmount = parseFloat(parseFloat(row.invoiceAmount) - parseFloat(row.appliedAmount) - parseFloat(row.prepayTotal) + (parseFloat(row.adjustmentAmount) || 0)).toFixed(2)
    },
    selectDataAction (data) {
      this.selectData = data
      this.$emit('changeSelectData', data)
    },
    onSelect (selection) {
      this.$nextTick(function () {
        this.$emit('resetInvoiceDetail', selection)
      })
    },
    handEdit (index, data) {
      this.dialogShow = true
      console.log('data', data)
      // var selectData = this.$refs.tabledeatailData.data
      // selectData.forEach(item => {
      //   this.orderCode = item.purchaseOrderCode
      //   this.rowNum = item.purchaseOrderItemCode
      // })
      var obj = {
        orderCode: data.purchaseOrderCode, // 采购单编号
        rowNum: data.purchaseOrderItemCode // 采购单行号
      }
      request('/api/reconciliation/pay/getPrePay', 'GET', obj).then((Response) => {
        if (Response.code === '200') {
          this.dialogdata = Response.data
        }
      })
    },
    handleClose () {
      this.dialogShow = false
    }
  }
}
</script>
<style scoped lang="scss">
  // @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
.tableList {
.table{
:deep(.el-table td) {
    padding: 0px 0;
}
:deep(.el-input__inner){
  height:32px;
  min-height:35px
}
}
}
</style>
