export var SearchList = [
  { labe: '供应商', code: 'supplier', type: 'input' },
  {
    labe: '到期日',
    code: 'maturityDate',
    type: 'daterange'
  },
  { labe: '开票方', code: 'drawer', type: 'input' },
  { labe: '公司', code: 'company', type: 'input' },
  { labe: '凭证编号', code: 'accountingDocumentCode', type: 'input' },
  { labe: '网上发票号', code: 'onlineInvoiceNumber', type: 'input' }
]
export var SearchData = {
  company: '',
  supplier: '',
  onlineInvoiceNumber: '',
  drawer: '',
  accountingDocumentCode: '',
  startTime: '',
  endTime: ''
}
export var tableField = [
  {
    type: 'input',
    selectType: 'selection',
    width: '50',
    selectable: function (data) {
      return true
    }
  },
  { label: '序号', code: '', type: 'function', width: '60', handle (index) { return index + 1 } },
  { label: '会计年度', code: 'accountingYear', type: 'input', width: '' },
  { label: '网上发票号', code: 'onlineInvoiceNumber', type: 'input', width: '200' },
  { label: '会计凭证编号', code: 'accountingDocumentCode', type: 'input', width: '110' },
  { label: '本位币', code: 'standardCoin', type: 'input', width: '' },
  { label: '应付金额', code: 'amountPayable', type: 'amount', align: 'right', width: '' },
  { label: '开票方 ', code: 'drawerName', type: 'input', width: '' },
  { label: '供应商', code: 'supplierName', type: 'input', width: '' },
  { label: '到期日', code: 'maturityDate', type: 'input', width: '120' },
  { label: '本次申请金额', code: 'applicationAmount', type: 'amount', align: 'right', width: '110' },
  { label: '已申请金额', code: 'amountApplied', type: 'amount', align: 'right', width: '110' },
  { label: '预付合计', code: 'prepayTotal', type: 'amount', align: 'right', width: '' },
  { label: '调整金额', code: 'adjustmentAmount', type: 'amount', align: 'right', width: '' },
  { label: '剩余可申请金额', code: 'remainingApplicableAmount', type: 'amount', align: 'right', width: '120' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '120' }
]

export var tableData = []

export var formData = {
  company: '',
  supplier: '',
  onlineInvoiceNumber: '',
  drawer: '',
  accountingDocumentCode: '',
  startTime: '',
  endTime: ''
}
