<template>
    <div class="mainform">
        <!-- 创建 付款申请 -->
       <div class="mainHeader">创建付款申请<span @click="back" class="back">返回></span></div>
        <div class="form">
            <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
            <div class="header">未清项</div>
            <global-table ref="nopayListdata"  max-height="350" :tableField="tableField" :tableData="tableData" @SelectionChange="SelectionChange"></global-table>
            <div class="header">网上发票明细</div>
            <onlineinvoice-detailstable @changeSelectData="setActionNum" @resetInvoiceDetail="resetInvoiceDetail" ref="onlineinvoicedetailtable" :invioceDetail="invioceDetail"></onlineinvoice-detailstable>
        </div>
        <div class="action" >
            <el-button class="submit" type="primary" size="medium" @click="nextBtn">下一步</el-button>
        </div>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, formData } from './js/noPayList'
import GlobalTable from '@/components/actionList/GlobalTable'
import OnlineinvoiceDetailstable from './OnlineinvoiceDetailstable'
import { request, deepClone } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: { ...Component.components, GlobalTable, OnlineinvoiceDetailstable },
  name: 'CreatePaymentreq',
  data () {
    tableField[0].selectable = this.canSelect
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: tableData,
      dialogShow: false,
      supName: '',
      noPaySelectData: [],
      comName: '',
      nSelectDataFpNum: {},
      form: { ...formData },
      invioceDetail: [],
      companyName: '',
      standardCoin: '',
      drawerName: '',
      selectDataCount: 0, // 网上发票明细选中数量
      changeSource: 'auto'
    }
  },
  mounted () {
    this.noPayList()
    this.$nextTick(() => {
      this.$refs.nopayListdata.$el.querySelector('.el-table__header-wrapper').querySelector('.el-table-column--selection').querySelector('.el-checkbox').remove()
    })
  },
  watch: {
    invioceDetail: {
      handler (newName, oldName) {
        this.setActionNum()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    canSelect (data) {
      if ((this.supName === '' && this.comName === '') || this.noPaySelectData.length <= 0) {
        return true
      }
      return this.supName === data.drawerName && this.comName === data.companyName
    },
    setActionNum () {
      Object.keys(this.nSelectDataFpNum).forEach((key) => {
        this.nSelectDataFpNum[key].applicationAmount = 0
        this.nSelectDataFpNum[key].adjustmentAmount = 0
      })
      if (this.$refs.onlineinvoicedetailtable) {
        for (const item of this.$refs.onlineinvoicedetailtable.selectData) {
          if (item.adjustmentAmount === null) {
            item.adjustmentAmount = 0
          }
          if (item.applicationAmount === null) {
            item.applicationAmount = 0
          }
          if (item.applicationAmount === null) {
            item.applicationAmount = 0
          }
          this.nSelectDataFpNum[item.onlineInvoiceNumber].applicationAmount = parseFloat(this.nSelectDataFpNum[item.onlineInvoiceNumber].applicationAmount) + ((item.applicationAmount === null || item.applicationAmount === '') ? 0 : parseFloat(item.applicationAmount))
          this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount = ((this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount === null || this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount === '') ? 0 : parseFloat(this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount)) + ((item.adjustmentAmount === undefined || item.adjustmentAmount === null || item.adjustmentAmount === '') ? 0 : parseFloat(item.adjustmentAmount))
          this.nSelectDataFpNum[item.onlineInvoiceNumber].applicationAmount = (this.nSelectDataFpNum[item.onlineInvoiceNumber].applicationAmount).toFixed(2)
          this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount = (this.nSelectDataFpNum[item.onlineInvoiceNumber].adjustmentAmount).toFixed(2)
        }
      }
    },
    /**
     * 当网上发票明细复选框更改时，重新计算发票明细数据
     */
    resetInvoiceDetail (selectedData) {
      const invioceDetail = deepClone(this.invioceDetail)
      // 默认所有的都是未选中
      invioceDetail.forEach(item => {
        item.isSelect = '0'
      })
      if (selectedData && selectedData.length > 0) {
        selectedData.forEach(selected => {
          invioceDetail.forEach(invoice => {
            if (invoice.onlineInvoiceNumber === selected.onlineInvoiceNumber && invoice.onlineInvoiceItemNumber === selected.onlineInvoiceItemNumber) {
              // 用户选中的数据更改标识为选中
              invoice.isSelect = '1'
            }
          })
        })
      }
      request('/api/reconciliation/pay/getSelectDetail', 'post', invioceDetail).then((Response) => {
        if (Response.code === '200') {
          const resData = Response.data || []
          this.invioceDetail.forEach(item => {
            resData.forEach(sData => {
              if (item.onlineInvoiceNumber === sData.onlineInvoiceNumber && item.onlineInvoiceItemNumber === sData.onlineInvoiceItemNumber) {
                item = Object.assign(item, sData)
              }
            })
          })
        }
      })
    },
    changeSelectData () {
      if (this.$refs.onlineinvoicedetailtable) {

      }
    },
    search: function (data) {
      this.searchdata = { ...data }
      delete this.searchdata.maturityDate
      if (data.maturityDate) {
        this.searchdata.startTime = this.dateFormate(data.maturityDate[0])
        this.searchdata.endTime = this.dateFormate(data.maturityDate[1])
      }
      request('/api/reconciliation/pay/list', 'GET', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    noPayList () { // 未清项
      request('/api/reconciliation/pay/list', 'GET', this.form).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data
        }
      })
    },
    SelectionChange (data) { // 网上发票明细
      this.changeSource = 'auto'
      this.noPaySelectData = data
      this.$refs.onlineinvoicedetailtable.$refs.tabledeatailData.clearSelection()
      var codeList = []
      this.nSelectDataFpNum = {}
      data.forEach(item => {
        codeList.push(item.onlineInvoiceNumber)
        this.supName = item.drawerName
        this.comName = item.companyName
        item.applicationAmount = 0
        item.adjustmentAmount = 0
        this.nSelectDataFpNum[item.onlineInvoiceNumber] = item
      })
      request('/api/reconciliation/pay/getDetail', 'post', codeList).then((Response) => {
        if (Response.code === '200') {
          this.invioceDetail = []
          for (const item of Response.data) {
            this.invioceDetail.push(...item.accountsPayableDetailList)
            for (const citem of this.tableData) {
              if (item.code === citem.onlineInvoiceNumber) {
                citem.amountApplied = item.appliedMoney
              }
            }
          }
          this.$refs.onlineinvoicedetailtable.$refs.tabledeatailData.toggleAllSelection()
        }
      })
    },
    // 到期日转换格式
    timeForm () {
      if (this.form.startTime) {
        this.form.startTime = dayjs(this.form.startTime).format('YYYY-MM-DD')
      } else {
        this.form.startTime = ''
      }
    },
    timeFormEnd () {
      if (this.form.endTime) {
        this.form.endTime = dayjs(this.form.endTime).format('YYYY-MM-DD')
      } else {
        this.form.endTime = ''
      }
    },
    nextBtn () {
      // 应付金额 amountPayable 本次申请金额applicationAmount
      var detaildata = this.$refs.onlineinvoicedetailtable.selectData
      var payapplay = this.noPaySelectData
      if (detaildata.length > 0) {
        const nextdata = { detaildata, payapplay }
        this.$store.commit('setNexData', nextdata)
        this.$router.push('/FinancialManagement/CreatePaymentreqNext')
        /* for (var item of this.$refs.nopayListdata.selectData) {
          item.applicationAmount = parseFloat(item.applicationAmount)
          if (item.amountPayable !== item.applicationAmount) {
            this.$message({
              message: '未清项中本次申请金额和应付金额不相等,不能进行下一步',
              type: 'warning'
            })
          } else {
            this.$router.push('/FinancialManagement/CreatePaymentreqNext')
          }
        } */
      } else {
        this.$message({
          message: '请选择要添加的明细',
          type: 'warning'
        })
      }
    },
    confirmAdd () {
      this.dialogShow = false
    },
    handleClose () {
      this.dialogShow = false
    },
    addDetail () {
      this.dialogShow = true
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
  .mainform{
    :deep(.tableList){
      padding: 0px;
    }
    :deep(.tableNav){
      border-bottom: none;
    }
  }
  .action{
    margin-top: 20px;
  }
</style>
